
let calcs = {
    prFieldsAll:[{key: 'uid',label: 'Username',sortable: false,"class":"col-uid"},
    {key: 'number',label: 'Mobile',sortable: false,"class":"col-number"},
    {key: 'email',label: 'Email',sortable: false,"class":"col-email"},
    {key: 'id',label: 'Referral ID',sortable: false,"class":"col-id"},
    {key: 'refAddress',label: 'Referrer ID',sortable: false,"class":"col-ref"},
    {key: 'rank',label: 'Rank',sortable: true,"class":"col-rank",sortByFormatted: true},
    {key: 'badge',label: 'Leader Score',sortable: false,"class":"col-badge"},

    {key: 'unverif',label: 'Unverified Ref.',sortable: true, sortByFormatted: true,"class":"col-unverif"
    },
    {key: 'verif',label: 'Verified Ref.',sortable: true, sortByFormatted: true,"class":"col-verif"
    },
    {key: 'total',label: 'Total Ref.',sortable: true, sortByFormatted: true,"class":"col-total"
    },
    {key: 'ipAddress',label: 'IP Address',sortable: true, sortByFormatted: true,"class":"col-verified"},
    {key: 'date',label: 'Created At',sortable: true, sortByFormatted: true,"class":"col-created"
    },
    {key: 'emailVerifiedAt',label: 'Verified At',sortable: true, sortByFormatted: true,"class":"col-verified"},
    {key: 'DNV',label: 'Days Not Verified',sortable: true, sortByFormatted: true,"class":"col-dnv"},
    {key: 'regPoints',label: 'Reg. Points',sortable: true,"class":"col-regp"},
    
    {key: 'ropt',label: 'Referrals Over Promoter Threshold',sortable: true, sortByFormatted: true,"class":"col-ropt"
    },
    {key: 'rupt',label: 'Referrals Under Promoter Threshold',sortable: true, sortByFormatted: true,"class":"col-rupt"
    },
    {key: 'probonus',label: 'Promoter Bonus',sortable: true, sortByFormatted: true,"class":"col-probonus"
    },
    {key: 'esPoints',label: 'Points (Estimated)',sortable: true, sortByFormatted: true,"class":"col-espoints"
    },
    {key: 'esPerc',label: 'Rewards Percentage (Estimated)',sortable: true, sortByFormatted: true,"class":"col-esperc"
    },
    
    {key: 'action',label: 'Actions',sortable: false,"class":"col-actions"}
    
    ],
    prFieldsPoints:[
    {key: 'uid',label: 'Username',sortable: false,"class":"col-uid"},
    {key: 'email',label: 'Email',sortable: false,"class":"col-email"},
    {key: 'id',label: 'Referral ID',sortable: false,"class":"col-id"},
    {key: 'refAddress',label: 'Referrer ID',sortable: false,"class":"col-ref"},
    {key: 'rank',label: 'Rank',sortable: true,"class":"col-rank",sortByFormatted: true},

    {key: 'unverif',label: 'Unverified Ref.',sortable: true, sortByFormatted: true,"class":"col-unverif"
    },
    {key: 'verif',label: 'Verified Ref.',sortable: true, sortByFormatted: true,"class":"col-verif"
    },
    {key: 'total',label: 'Total Ref.',sortable: true, sortByFormatted: true,"class":"col-total"
    },
    {key: 'regPoints',label: 'Reg. Points',sortable: true,"class":"col-regp"},
    
    
    {key: 'esPoints',label: 'Points (Estimated)',sortable: true, sortByFormatted: true,"class":"col-espoints"
    },
    {key: 'action',label: 'Actions',sortable: false,"class":"col-actions"}
    
    ],
    prFieldsCompact:[
    {key: 'uid',label: 'Username',sortable: false,"class":"col-uid"},

    {key: 'regPoints',label: 'Reg. Points',sortable: true,"class":"col-regp"},
    
    {key: 'ropt',label: 'Referrals Over Promoter Threshold',sortable: true, sortByFormatted: true,"class":"col-ropt"
    },
    {key: 'rupt',label: 'Referrals Under Promoter Threshold',sortable: true, sortByFormatted: true,"class":"col-rupt"
    },
    {key: 'probonus',label: 'Promoter Bonus',sortable: true, sortByFormatted: true,"class":"col-probonus"
    },
    {key: 'esPoints',label: 'Points (Estimated)',sortable: true, sortByFormatted: true,"class":"col-espoints"
    },
    {key: 'esPerc',label: 'Rewards Percentage (Estimated)',sortable: true, sortByFormatted: true,"class":"col-esperc"
    },
    
    {key: 'action',label: 'Actions',sortable: false,"class":"col-actions"}
    
    ],
    getBadge(id, refCountVerified, totalReferredVerified, totalReward, peakValue, div){
        if (refCountVerified[id]) {
        
        let num =(Number((refCountVerified[id] / totalReferredVerified) ) * totalReward).toFixed(2)
        num = Number(num)
                    // console.log(Object.keys(ranks), num)
                    let res = "Data DDS";
                    let cod = peakValue
                    cod = Number(cod)
                    if (num == cod) {
                      res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                        ` 
                    }else{
                      let codTwo = cod - div
                      if (num > codTwo) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                         let codThree = codTwo - div
                         if (num > codThree) {
                         res =`
                         <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                         <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                         `
                         }
                         else
                         {
                        let codFour = codThree - div
                      if (num > codFour) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/diamond.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let codFive = codFour - div
                      if (num > codFive) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/platinum.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/platinum.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/platinum.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let codSix = codFive - div
                      if (num > codSix) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/platinum.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/platinum.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let codSeven = codSix - div
                      if (num > codSeven) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/platinum.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let codEight = codSeven - div
                      if (num > codEight) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pdownload.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let codNine = codEight - div
                      if (num > codNine) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pdownload.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let codTen = codNine - div
                      if (num > codTen) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pdownload.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let codEleven = codTen - div
                      if (num > codEleven) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gdownload.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let codEleven = codTen - div
                      if (num > codEleven) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gdownload.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod12 = codEleven - div
                      if (num > cod12) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gdownload.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod13 = cod12 - div
                      if (num > cod13) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/rdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/rdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/rdownload.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let cod14 = cod13 - div
                      if (num > cod14) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/rdownload.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/rdownload.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod15 = cod14 - div
                      if (num > cod15) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/rdownload.png' class='float-left' style='width:45px;' />
                        />
                        `
                      }else{
                        let cod16 = cod15 - div
                      if (num > cod16) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/jade.png' class='float-left' style='width:32px;margin-right:6px;margin-left:6px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/jade.png' class='float-left' style='width:32px;margin-right:6px;margin-left:6px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/jade.png' class='float-left' style='width:32px;margin-right:6px;margin-left:6px;' />
                        `
                      }else{
                        let cod17 = cod16 - div
                      if (num > cod17) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/jade.png' class='float-left' style='width:32px;margin-right:6px;margin-left:6px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/jade.png' class='float-left' style='width:32px;margin-right:6px;margin-left:6px;' />
                        
                        `
                      }else{
                        let cod18 = cod17 - div
                      if (num > cod18) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/jade.png' class='float-left' style='width:32px;margin-right:6px;margin-left:6px;' />
                        
                        `
                      }else{
                        let cod19 = cod18 - div
                      if (num > cod19) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pearl.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pearl.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pearl.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let cod20 = cod19 - div
                      if (num > cod20) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pearl.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pearl.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod21 = cod20 - div
                      if (num > cod21) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/pearl.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod22 = cod21 - div
                      if (num > cod22) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gold.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gold.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gold.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let cod23 = cod22 - div
                      if (num > cod23) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gold.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gold.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod24 = cod23 - div
                      if (num > cod24) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/gold.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod25 = cod24 - div
                      if (num > cod25) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/silver.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/silver.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/silver.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let cod26 = cod25 - div
                      if (num > cod26) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/silver.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/silver.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod27 = cod26 - div
                      if (num > cod27) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/silver.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod28 = cod27 - div
                      if (num > cod28) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />
                        `
                      }else{
                        let cod29 = cod28 - div
                      if (num > cod29) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />
                        
                        `
                      }else{
                        let cod30 = cod29 - div
                      if (num > cod30) {
                        res =`
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />
                        
                        `
                      }
                      
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    }
                    if (res == "Data DDS") {
                      res =`<img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />`
                      
                    }
                    
                    
                    // sfu = res
                    return res
                  } else {
                    // return `<b>${0} </b>`
                    return `
                        <img src='https://fuseg-dev-app-u6wsh.ondigitalocean.app/gems/bronze.png' class='float-left' style='width:45px;' />
                        
                        `
                  }
      },
    getTableFields(users, refCountVerified, totalReferredVerified, totalReward, refCount, rewards, totalPoints, ranks, peakValue, div, goldxRewards ){
        console.log("params reached in gettable fields", refCountVerified, totalReferredVerified, totalReward, refCount, rewards, totalPoints, ranks, peakValue, div, goldxRewards)
        let elements = []
        
        users.forEach(item => {
            let element = {
                rewards:'',
                uid:'',
                 number:'',
                 firstName:'',
                 lastName:'',
                 email:'',
                 emailVerified:'',
                 id:'',
                 refAddress:'',
                 rank:'',
                 badge:'',
                 unverif:'',
                 verif:'',
                 total:'',
                 ipAddress:'',
                 emailVerifiedAt:'',
                 date:'',
                 regPoints:'',
                 ropt:'',
                 rupt:'',
                 probonus:'',
                 esPoints:'',
                 esPerc:'',
                 DNV:null,
                 username:''
            }
            element.username = item.username
            element.firstName = item.firstName
            element.lastName = item.lastName
            element.uid = item._id
            element.id = item._id
            element._id = item._id
            element.number = item.number
            element.email = item.email
            element.emailVerified = item.emailVerified
            if (!element.emailVerified) {
             let date = new Date()
             let regDate = new Date(item.date)
             let difference = date - regDate
             element.DNV =  Math.floor((((difference/1000) / 60) / 60) / 24)  

            }
            element.badge = this.getBadge(element._id, refCountVerified, totalReferredVerified, totalReward, peakValue, div)
            if (refCountVerified[item._id]) 
            {
                let num = (Number((refCountVerified[item._id] / totalReferredVerified) ) * totalReward).toFixed(2)
                       element.rank = ranks[num.toString()]
                     } else {
                       let n = '0.00'
                       element.rank = ranks[n]
            }
            element.unverif = (refCount[item._id]) ? refCount[item._id] : 0
            let verif = (refCountVerified[item._id]) ? refCountVerified[item._id] : 0
            // console.log("verif result is", verif)
            element.verif = verif
            element.total = element.unverif + element.verif;
            element.ipAddress = item.ipAddress;
            element.date = new Date(item.date).toLocaleString()
            element.emailVerifiedAt = (item.emailVerifiedAt) ? new Date(item.emailVerifiedAt).toLocaleString() : new Date(item.date).toLocaleString()
            element.regPoints = (item.refAddress) ? rewards.RegistrationReferral : rewards.RegistrationNonReferral
            element.ropt = (element.verif >= rewards.PromoterThreshold) ? element.verif-rewards.PromoterThreshold : 0;
            element.rupt = element.verif - element.ropt;
            element.probonus = (((refCountVerified[item._id]) ? refCountVerified[item._id] : 0) > rewards.PromoterThreshold) ? rewards.PromoterBonus : 0
            // console.log("params used in esPoints", element.regPoints,element.ropt,rewards.PromterRate,element.rupt,rewards.RegistrationReferrer, element.probonus)
            element.esPoints = (element.regPoints + (element.ropt*rewards.PromterRate) + (element.rupt*rewards.RegistrationReferrer) + element.probonus )
            element.esPerc = Number(((element.esPoints/totalPoints)*100).toFixed(2))
            element.rewards = Number( ((element.esPoints/totalPoints) * goldxRewards).toFixed(2)  )
            elements.push(element)


        });
        return elements;

    }
}
export default calcs
