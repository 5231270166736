<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div class="row justify-content-center p-0 m-0" v-if="LDATA">
              <div class="col-12 col-lg-12 col-xl-12 p-0 m-0">
<div class='row m-0 p-0'>
  <!-- <div class="col-12">
    <div class="float-left" style="
    width: 245px;">
                        <b-form-checkbox v-model="checked" size="lg" name="check-button" switch>
                          <b v-if="checked">Test </b>
                          <b v-else>  Pro  </b>
                        </b-form-checkbox>
                        
                      </div>
  </div> -->
  
                <div class="col-12 p-0 m-0 px-lg-2 my-lg-5 " >
                   <div class="row clLight p-0 m-0 justify-content-between">
                    
                  
                    <!-- {{AppData}} -->
                    
                   <div class="col-12 col-md-12 col-lg-4 px-0 py-4 m-0">
                    <h5 style="padding-left:30%;" class="txGold d-none d-lg-block"> GLOBAL GOLDX REFERRAL STATS  </h5>
                    <h5 style="    font-size: 4vw;" class="txGold d-lg-none px-4"> GLOBAL GOLDX REFERRAL STATS  </h5>
                    <h5 style="padding-left:30%;" class="d-none d-lg-block bggold py-2 pr-2 text-white txThin"> Total Registrations 
                    <span class="float-right " > 
                      <!-- {{ (AppData) ? AppData.data.totalRegs.toLocaleString() : (promUsers.length).toLocaleString() }}  -->
                      {{ LDATA.totals.toLocaleString() }}
                    </span>
                    </h5>
                    <h5 style="    font-size: 4vw;" class="px-4 bggold d-lg-none py-2 pr-2 text-white txThin"> Total Registrations 
                    <span class="float-right " > 
                      <!-- {{(AppData) ? AppData.data.totalRegs.toLocaleString() : (promUsers.length).toLocaleString() }} -->
                      {{ LDATA.totals.toLocaleString() }}
                    
                    </span>
                    </h5>
                    <h5 style="padding-left:30%;" class="up txThin d-none d-lg-block up"> Verified Registrations 
                    <span class="float-right txgold-right txGold" > 
                      <!-- {{ (AppData) ? AppData.data.totalVerified.toLocaleString() : totalVerified.toLocaleString()}}   -->
                      {{ LDATA.totalsVerifs.toLocaleString() }}
                    
                  </span>
                    </h5>
                    <h5 style="    font-size: 4vw;" class="up txThin px-4 d-lg-none up"> Verified Registrations 
                    <span class="float-right txgold-right txGold" > 
                      <!-- {{(AppData) ? AppData.data.totalVerified.toLocaleString() : totalVerified.toLocaleString()}}  -->
                      {{ LDATA.totalsVerifs.toLocaleString() }}
                    </span>
                    </h5>
                    
                    <h5 style="padding-left:30%;background:rgb(185, 150, 83);" class=" d-none d-lg-block bggold py-2 pr-2 text-white txThin"> Total Referrals <span class=" float-right">
                       <!-- {{(AppData) ? AppData.data.totalReferrals.toLocaleString() : totalReferrals.toLocaleString()}}  -->
                       {{ LDATA.totalsRefs.toLocaleString() }}
                      </span>  </h5>
                     <h5 style="    font-size: 4vw;background:rgb(185, 150, 83);" class="px-4 bggold d-lg-none py-2 pr-2 text-white txThin"> Total Referrals <span class=" float-right"> 
                      {{ LDATA.totalsRefs.toLocaleString() }}
                      <!-- {{(AppData) ? AppData.data.totalReferrals.toLocaleString() : totalReferrals.toLocaleString()}}  -->
                    </span>  </h5>
                     
                     <h5 style="padding-left:30%;" class="up txThin d-none d-lg-block"> Verified Referrals 
                        <span class="float-right txgold-right txGold" > 
                           <!-- {{(AppData) ? AppData.data.totalReferredVerified.toLocaleString() : totalReferredVerified.toLocaleString()}}  -->
                           {{ LDATA.totalsRefsVerifs.toLocaleString() }}
                          </span>
                        <!-- <span class="float-right txgold-right txGold" v-else> {{0}}% </span> -->
                       </h5>
                       <h5 style="    font-size: 4vw;" class="up txThin px-4 d-lg-none"> Verified Referrals 
                        <span class="float-right txgold-right txGold" > 
                          {{ LDATA.totalsRefsVerifs.toLocaleString() }}
                           <!-- {{(AppData) ? AppData.data.totalReferredVerified.toLocaleString() : totalReferredVerified.toLocaleString()}}  -->
                           </span>
                        <!-- <span class="float-right txgold-right txGold" v-else> {{0}}% </span> -->
                       </h5>
                       <!-- <h5 style="padding-left:30%;" class="up txThin"> Total Verified KYC <span class="txGold float-right txgold-right"> 0% </span>  </h5> -->
                      
                       <h5 style="padding-left:30%;" class="up txThin d-none d-lg-block"> TOTAL GOLDX REWARDS 
                    <span class="float-right txgold-right txGold"> 
                      {{ LDATA.rewards.data.TOTAL_REWARDS.toLocaleString() }}
                      <!-- {{(AppData) ? AppData.data.goldxRewards.toLocaleString() : goldxRewards.toLocaleString()}}  -->
                    </span>
                       </h5>

                       <h5 style="    font-size: 4vw;" class="up txThin d-lg-none px-4"> TOTAL GOLDX REWARDS 
                    <span class="float-right txgold-right txGold"> 
                      {{ LDATA.rewards.data.TOTAL_REWARDS.toLocaleString() }}

                      <!-- {{(AppData) ? AppData.data.goldxRewards.toLocaleString() : goldxRewards.toLocaleString()}}  -->
                    </span>
                       </h5>


                       <h5 style="padding-left:30%;" class="up txThin d-none d-lg-block"> TOTAL REWARD POINTS 
                    <span class="float-right txgold-right txGold"> 
                      <!-- {{(AppData) ? AppData.data.totalPoints.toLocaleString() : totalPoints.toLocaleString()}}  -->
                      {{ LDATA.rewards.data.TOTAL_POINTS.toLocaleString() }}

                    </span>
                       </h5>

                       <h5 style="    font-size: 4vw;" class="up txThin d-lg-none px-4"> TOTAL REWARD POINTS 
                    <span class="float-right txgold-right txGold"> 
                      {{ LDATA.rewards.data.TOTAL_POINTS.toLocaleString() }}
                      <!-- {{(AppData) ? AppData.data.totalPoints.toLocaleString() : totalPoints.toLocaleString()}}  -->
                    </span>
                       </h5>

                      </div>
                      <div class="col-lg-4 py-3">
                        <!-- <div class="container d-flex h-100"> -->
    <!-- <div class="row justify-content-center align-self-center"> -->
      <img src="@/assets/images/goldx.png"  height="auto" width="220.38" alt="GOLDX" v-if="$store.state.dark" class="gx-img d-block mx-auto" style="max-width:50%;">
      <img src="@/assets/images/gold x logo b.png"  height="auto" width="220.38" alt="GOLDX" v-else class="gx-img d-block mx-auto" style="max-width:50%;">
     
    <!-- </div> -->
<!-- </div> -->
                      </div>
                    <div class="col-12 col-md-12 col-lg-4 p-4 m-0 bggold" style="background:rgb(185, 150, 83)">
                      <h5 class="text-white d-lg-none" style="    font-size: 4vw;"> FUSE WALLET  
                          <span class="f400"> Wallet Support Coming Soon</span>
                      </h5>
                      <h5 class="text-white d-none d-lg-block"> FUSE WALLET  
                          <span class="f400"> Wallet Support Coming Soon</span>
                      </h5>
                      
                      <h5 class="my-3 text-white d-none d-lg-block"> <b>0.0000 FUSEG</b> <span class="f400"> in Wallet (0000.00$)</span>  </h5>
                      <h5 class="my-3 text-white d-none d-lg-block"> <b>0.0000 GOLDX</b> <span class="f400"> in Wallet (0000.00$)</span>  </h5>
                      <h5 class="my-3 text-white d-lg-none" style="font-size:4vw;"> <b>0.0000 FUSEG</b> <span class="f400"> in Wallet (0000.00$)</span>  </h5>
                      <h5 class="my-3 text-white d-lg-none" style="font-size:4vw;"> <b>0.0000 GOLDX</b> <span class="f400"> in Wallet (0000.00$)</span>  </h5>
                      <h5 class="my-0 text-white float-left d-none d-lg-block">My referral Leader Score 
                        <span class="d-inline-block limages pl-2" style="width:150px;"  v-html="sfu"></span>
                        <!-- <span v-else class="f400"> Use Filter To Identify </span>  -->
                      
                      </h5>
                      <h5 class="text-white d-lg-none mb-5 mb-md-2" style="font-size:4vw;">My referral Leader Score 
                        <span class="d-inline-block limages pl-2" style="width:150px;"  v-html="sfu"></span>
                        <!-- <span v-else class="f400"> Use Filter To Identify </span>  -->
                      
                      </h5>
                      <!-- <div style="height:auto;">

                      </div> -->
                      <div class="col-12 mx-0 px-0 d-lg-none">
                          <div :class="[($store.state.dark) ? 'bg-dark' : 'bg-white']" class="px-2  mt-2 m-md-0 py-1">
                          <input :class="[($store.state.dark) ? 'bg-dark' : 'bg-white']" type="text" name="" v-model="search" style="width:75%;" 
                          placeholder="Enter Your Username" id=""  class="border-left-gold border-input">
                          <img src="@/assets/images/zoom.png" @click="searchRank()" style="cursor:pointer;width: 35px;
    float: right;" alt="">  
                        </div>
                        </div>







                      
                    </div>  
                    
                   </div>  
                   <div class=" d-lg-none" style="height:96px;">
                       <div class="float-right"> <button-shimmer-nav-id></button-shimmer-nav-id></div>
                    </div>
                    <div class=" d-none d-lg-block" style="height:43px;">
                       <div class="float-right"> <button-shimmer-nav-id></button-shimmer-nav-id></div>
                    </div>
<!-- 
                    <div class=" d-lg-none" style="height:96px;margin-top:30px;">
                       <div class="float-right"> <button-shimmer-nav-nick></button-shimmer-nav-nick></div>
                    </div>
                    <div class=" d-none d-lg-block" style="height:43px;margin-top:30px;">
                       <div class="float-right"> <button-shimmer-nav-nick></button-shimmer-nav-nick></div>
                    </div> -->
                   <div class="row px-lg-5 py-3 m-0">
                    <div class="col-12 p-0 m-0" v-if="LDATA !== null">
      <state-leaderboard :ranksraw="ranks" :is-admin="false" :heatmapraw='promUsersFilteredTableForStates' :data="LDATA"></state-leaderboard>
  </div>
                    <div class="col-12 px-3 m-0 mb-5">
                      <h2 class="ld-title d-none d-lg-block">REFERRALS LEADER BOARD</h2>
                      <h2 class="ld-title d-lg-none text-center" style="font-size:12vw;">REFERRALS LEADER BOARD</h2>
                    </div>
                    <!-- <div class="col-12"> <button class="btn btn-primary" @click="updateUsernames">Update Usernames  </button> </div> -->

                   <div class="col-12 p-0 m-0 px-lg-4 py-lg-2">
                    <div class="header-gold mt1m ml1m mr1m d-none d-lg-block">
                      <div class="row">
                        <div class="col-12 col-lg-6 p-0 m-0"> 
                          <div class=" mb-0 t8White p-lg-3">
                            <button class="btn-leader ml-3 px-lg-5 px-3 py-2" :class="{actives:perPage == 10}"  @click="perPage = 10" ><b>TOP 10</b></button>
                            <button class="btn-leader ml-3 px-lg-5 px-3 py-2" :class="{actives:perPage == 100}" @click="perPage = 100" ><b>TOP 100</b></button>
                            <button class="btn-leader ml-3 px-lg-5 px-3 py-2" :class="{actives:perPage == 1000}" @click="perPage = 1000" ><b>TOP 1000</b></button>
                          </div> 
                        </div>
                        <div class="col-12 col-lg-6 p-0 m-0">
                        <div class="mb-0 t8White p-lg-3 float-right" >
                          <div :class="[($store.state.dark) ? 'bg-dark' : 'bg-white']" class="px-2 py-1" style="width:400px;max-width: 100%">
                          <input :class="[($store.state.dark) ? 'bg-dark' : 'bg-white']"  type="text" name="" v-model="search" style="width:300px;" placeholder="Enter Your Username" id=""  class="border-left-gold border-input">
                          <img src="@/assets/images/zoom.png" @click="searchRank()" style="cursor:pointer;width: 35px;
    float: right;" alt="">
                        </div>
                        </div>
                      </div>

                    </div>
      </div>
      <div class="header-gold p-md-2 d-lg-none mb-4">
                      <div class="row p-0 m-0">
                        <div class="col-12 col-md-6 p-0 m-0"> 
                          <div class=" mb-0 t8White p-lg-3 pt-2 pt-md-1">

                            <button class="btn-leader py-2" style="width:30%;margin-left:2.4%;" 
                            :class="{actives:perPage == 10}"  @click="perPage = 10" ><b>TOP 10</b></button>
                            <button class="btn-leader py-2" style="width:30%;margin-left:2.4%;" 
                            :class="{actives:perPage == 100}" @click="perPage = 100" ><b>TOP 100</b></button>
                            <button class="btn-leader py-2" style="width:30%;margin-left:2.4%;" 
                            :class="{actives:perPage == 1000}" @click="perPage = 1000" ><b>TOP 1000</b></button>
                          </div> 
                        </div>
                        <div class="col-12 col-md-6 p-0 m-0">
                          <div :class="[($store.state.dark) ? 'bg-dark' : 'bg-white']" 
                          class="px-2  mt-2 m-md-0 py-1" style="width:400px;max-width: 99%;
    margin: 0% 0%;">
                          <input :class="[($store.state.dark) ? 'bg-dark' : 'bg-white']" type="text" name="" v-model="search" style="width:275px;" 
                          placeholder="Enter Your Username" id=""  class="border-left-gold border-input">
                          <img src="@/assets/images/zoom.png" @click="searchRank()" style="cursor:pointer;width: 35px;float: right;" alt="">  
                        </div>
                      </div>
                    </div>
      </div>
                   </div>
                    <div class="col-12 p-0 m-0 p-lg-4" >
                    <!-- Total:{{promUsersFiltered.length}} Filterd <br>
                    Total:{{promUsers.length}} UnFilterd -->
                    <!-- <div class="position-relative" v-if="updating">
                    <p class="txGold float-right position-absolute"  style="top:-28px;">Updating......</p>

                    </div> -->
                    <div v-if="LDATA">
                     <b-table 
                     sort-by="number" :sort-desc="false" sort-icon-right  tbody-class="gold-body"  head-variant="gold" 
                      responsive :items="LDATA.users.slice(0, perPage)" class="gold-table" :fields="prfields">
                      <template #head(total)="data"><span >Total <br> Ref.</span></template>
                      <template #head(verif)="data"><span >Verified <br> Ref.</span></template>
                      <template #head(unverif)="data"><span >Unverified <br> Ref.</span></template>
                      <template #head(epoints)="data"><span >Points<br>(Estimated)</span></template>
                      <template #head(esx)="data"><span ><br>GOLDX Rewards <br>(Estimated)</span></template>
                 <template #cell(badge)="data"> <div v-html="data.item.leaderScore">
                </div> </template>  
                 <template #cell(username)="data"> {{data.item.username }} </template>  
                 <template #cell(rewards)="data"> {{data.item.rewards.toLocaleString() }} </template>  
                 <template #cell(esPoints)="data"> {{data.item.points.toLocaleString() }} </template>  
                 <template #cell(total)="data"> {{Number(data.item.totalRef).toLocaleString() }} </template>  
                 <template #cell(verif)="data"> {{Number(data.item.verifiedRef).toLocaleString() }} </template>  
                 <template #cell(unverif)="data"> {{ (Number(data.item.totalRef) - Number(data.item.verifiedRef)) }} </template>  
                     </b-table>
                    </div>
                    <div v-else>
                      <img src="@/assets/loader.gif" class="d-block mx-auto" style="max-width:300px;" alt="" srcset="">
                    </div>
                    
                    
                </div>  
                  
                </div>
                
                   </div>

              </div> 
      </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>
  //QR API 1jZnfiPhrJH2ptYFdBu8
import {mapState} from 'vuex';
import calcs from './DashboardCalcs';
import { BTable } from 'bootstrap-vue'
export default {
   components:{
    BTable,
    NavBar:() => import('@/components/NavBar.vue'),
    FooterSection:() => import('@/components/FooterSection.vue'),
    ButtonShimmerNavId:() => import('@/components/ButtonShimmerNavId.vue'),
    ButtonShimmerNavNick:() => import('@/components/ButtonShimmerNavNick.vue'),
    StateLeaderboard:() => import('@/components/StateLeaderboard.vue'),

   },
   async mounted(){
     
      // await this.$store.dispatch('getleaderboardData');
      // await this.$store.dispatch('getCachedData', {type:"Leaderbaord"});
      // await this.$store.dispatch('getPromUsers', {});
      // await this.$store.dispatch('getRewardConfig', {});
      // this.rewards = this.$store.state.rewardConfig
      // this.figureRanks(this.promUsers);
      // setInterval(async() => {
      this.updating = false
      // await this.$store.dispatch('getPromUsersPro', {});
      // this.figureRanks(this.promUsers)
      // this.updating = false

      // }, 60000);
    


   },
//    watch:{
//     async checked(value){
//       // console.log("new checkd value is ", this.checked, value)
//       if (value) {
//         await this.$store.dispatch('getPromUsersPro', {});
//       this.figureRanks(this.promUsers)

        
//       }else{
//         await this.$store.dispatch('getPromUsersPro', {});
//       this.figureRanks(this.promUsers)

        
//       }
//     }
// },
   computed:{
    ...mapState(["AppData",'user','refCount','totalReferred','refCountVerified','totalReferredVerified','promUsers','LDATA']),
    // totalReferrals(){
    //   return this.totalReferred+this.totalReferredVerified
    // },
    // totalVerified(){
    //   let users = 0
    //   this.promUsers.forEach(element => {
    //     if(element.emailVerified) users++
    //   });
    //   return users
    // },
    // numberOfRegistrations(){
    //     return this.promUsers.length;
    //   },
      // amountOverUpperThreshold(){
        
      //   return (this.numberOfRegistrations > this.rewards.referralUpperThreshold) ? this.numberOfRegistrations - this.rewards.referralUpperThreshold : 0
      // },
      // numberOfIncreaments(){
      //   return Number(Math.floor(this.amountOverUpperThreshold / this.rewards.increamentStepSize))
      // },
    //   goldxRewards(){
    //     console.log("in goldxRewards", this.rewards.baseGoldxRewards, this.numberOfIncreaments , this.rewards.increamentAmount)
    //  return (Number(this.rewards.baseGoldxRewards) + (this.numberOfIncreaments * this.rewards.increamentAmount))
    // },
    
    // promUsersFiltered(){
    //   let records = [];
    //   if (this.search.length) {
    //     this.promUsers.forEach(element => {
    //       let uss = (element.username) ? element.username : ''
    //       if (uss.includes(this.search) ) {
    //         records.push(element)
    //       }
    //     });
    //   }else{
    //     records = this.promUsers
    //   }
    //   return records;
    // },
    // promUsersFilteredTableFiltered(){
    //   console.log("filtering last data");
    //     let records = [];
    //     let Allrecords = this.promUsersFilteredTable;
    //     if (this.search.length) {
    //       this.promUsersFilteredTable.forEach(element => {
    //         // let uss = (element.username) ? element.username : ''
    //         if (
    //            element.username.toLowerCase().includes(this.search.toLowerCase()) 
    //         ) {
    //           records.push(element)
    //         }
    //       });
    //     }else{
    //       records = this.promUsersFilteredTable
         
    //     }
    //     let sorted = Allrecords.sort((a,b) => a.rank - b.rank);
    //     if (sorted.length) {
         
    //       let final = sorted.slice(0, 99)
    //       this.cachedData = {}
    //       this.cachedData.users = final
    //       this.cachedData.totalRegs = this.numberOfRegistrations
    //       this.cachedData.totalVerified = this.totalVerified
    //       this.cachedData.totalReferrals = this.totalReferrals
    //       this.cachedData.totalReferredVerified = this.totalReferredVerified
    //       this.cachedData.goldxRewards = this.goldxRewards
    //       this.cachedData.totalPoints = this.totalPoints
    //       if(this.cachedData.users.length > 50){
    //         this.$store.dispatch("saveCachedData", {data: this.cachedData, type:"Leaderbaord"})
    //       }
    //     }
    //     return records;
    //   }

    
},
methods:{
  async searchRank(){
    if(this.search.length > 2){
      let dec = await this.$store.dispatch("searchRank", {search: this.search})
      console.log("searchRank",this.search,dec)
    }
  },
  async loadTable(){
      // promUsersFilteredTable(){
        // this.promUsersFilteredTable = await calcs.getTableFields(this.promUsers, this.refCountVerified, this.totalReferredVerified, this.totalReward, this.refCount, this.rewards, this.totalPoints, this.ranks, this.peakValue, this.div, this.goldxRewards)
        // this.promUsersFilteredTableForStates = await calcs.getTableFields(this.promUsers, this.refCountVerified, this.totalReferredVerified, this.totalReward, this.refCount, this.rewards, this.totalPoints, this.ranks, this.peakValue, this.div, this.goldxRewards)
        // this.promUsersFilteredTableForStates = this.promUsersFilteredTable
      // },
      this.$store.state.AppData = null
    },
  figureTotalPoints()
    {
      this.totalPoints = 0
      this.promUsers.forEach(item => {
        let unitOne = (item.refAddress) ? this.rewards.RegistrationReferral : this.rewards.RegistrationNonReferral ; 
            let verifs = (this.refCountVerified[item._id]) ? this.refCountVerified[item._id] : 0
            let ropt = (verifs > this.rewards.PromoterThreshold) ? verifs-this.rewards.PromoterThreshold : 0;
            let rupt = verifs - ropt;
            let points = (unitOne + (ropt*this.rewards.PromterRate) + (rupt*this.rewards.RegistrationReferrer) )
            this.totalPoints = this.totalPoints + points
      });
      this.loadTable()
    },
  updateUsernames(){
    this.$store.dispatch("updateUsernames")
  },
  
  figureRanks(records){
    // console.log("records received on", records)
    this.ranks = {}
    let percentage = []
    records.forEach(element => {
      if (this.refCountVerified[element._id]) {
        let num =(Number((this.refCountVerified[element._id] / this.totalReferredVerified) ) * this.totalReward).toFixed(2)
                percentage.unshift(Number(num))
              } else{
                percentage.unshift(0)                
              }
    });
    let uniq = [...new Set(percentage)];
    uniq.sort(function(a, b) {
    if( a === Infinity )  return 1; 
    else if( isNaN(a)) return -1;
    else return a - b;
  });
  uniq.reverse()
  let ranks = {}
  this.peakValue = uniq[0];
  this.div = this.peakValue / 30
  uniq.forEach((element, index) => {
    let el = element.toFixed(2)

    ranks[el] = index+1
  });
    console.log("calcs ranks object is", ranks)
    this.ranks = ranks
    this.figureTotalPoints()

  },
},
    name: "LeaderBoard.vue",
    data(){
     return {
      promUsersFilteredTableForStates:[],
      cachedData:null,
      promUsersFilteredTable:[],
      rewards:{},
      totalPoints:0,
      statistics:false,
      sfu:'',
      bgs:{},
      scores:{},
      checked:false,
      currentPage:1,
      perPage:10,
      updating:false,
      search:'',
      peakValue:null,
      div:null,
      ranks:{},
      prfields:[
        {key: 'username',label: 'Username',sortable: false,"class":"col-uid"},
        {key: 'number',label: 'Rank #',sortable: true,"class":"col-rank",sortByFormatted: true},
      {key: 'badge',label: 'Leader Score',sortable: false,"class":"col-badge"},

        {key: 'total',label: 'Total Ref.',sortable: true, sortByFormatted: true,"class":"col-total"
      },
        {key: 'verif',label: 'Verified Ref.',sortable: true, sortByFormatted: true,"class":"col-verif"
      },
        {key: 'unverif',label: 'Unverified Ref.',sortable: true, sortByFormatted: true,"class":"col-unverif"
      },
      {key: 'esPoints',label: 'Points (Estimated)',sortable: true,"class":"col-epoints-le",
        sortByFormatted: false
      
      },
      
        {key: 'rewards',label: 'GOLDX Rewards (Estimated)',sortable: true,"class":"col-esx-le",
        sortByFormatted: false,
      
      
      },
      ],
      totalVerifiedKyc:0,
      totalReward:500000,
     }
    }
}
</script>

<style scoped>
</style>